import React from "react";
import "./mediaSecondLayer.css";
import PressRelease from '../../data/pressReleaseNews.json'
import MediaSecondLayerImage1 from "../../images/pressReleaseImg1.jpg";
import MediaSecondLayerImage2 from "../../images/pressReleaseImg2.jpeg";
import MediaSecondLayerImage3 from "../../images/pressReleaseImg3.jpg";
import MediaSecondLayerChev1 from "../../images/vector-63.svg";
import MediaSecondLayerimg4 from "../../images/mask-group@2x.png";
import MediaSecondLayerChev2 from "../../images/vector-177.svg";
import MediaSecondLayerChev3 from "../../images/vector-06.svg";

export default function MediaSecondLayer() {

  const createSlugFromTitle = (title) => {
    return title
      .toLowerCase()
      .replace(/[^\w\s./-]/g, '')  // Allow dots, slashes, and hyphens
      .replace(/\//g, '-')         // Replace slashes with hyphens
      .trim()
      .replace(/\s+/g, '-')        // Replace spaces with hyphens
      .replace(/-+/g, '-');        // Remove duplicate hyphens
  };


  return (
    <div>
      <div className="mediaPressRel-blog-34">
        <div className="mediaPressRel-blog-34-inner">
          <div className="mediaPressRel-heading-parent">
            <div className="mediaPressRel-heading">Press Release</div>
            <a href="/press-release" className="mediaPressRel-button3">
              <div className="mediaPressRel-button-child" />
              <img
                className="mediaPressRel-button-item"
                alt=""
                src={MediaSecondLayerChev2}
              />
              <div className="mediaPressRel-see-more-parent">
                <div className="mediaPressRel-see-more1">See More</div>
                <img
                  className="mediaPressRel-group-child"
                  alt=""
                  src={MediaSecondLayerChev3}
                />
              </div>
            </a>
          </div>
        </div>
        <div className="mediaPressRel-content3">
          <div className="mediaPressRel-row">

            {
              PressRelease.reverse().slice(0,3).map((news)=>(
                <div className="mediaPressRel-card">
                  <div className="mediaPressRel-placeholder-image-parent">
                    <img
                      className="mediaPressRel-placeholder-image-icon"
                      alt=""
                      src={require(`../../images/${news.imageURL}`).default}
                    />
                    <div className="mediaPressRel-mask-group-parent">
                      <img
                        className="mediaPressRel-mask-group-icon"
                        alt=""
                        src={MediaSecondLayerimg4}
                      />

                      <div className="mediaPressRel-feb">
                        <p className="mediaPressRel-p">{news.date}</p>
                        <p className="mediaPressRel-feb1">{news.month}</p>
                      </div>
                    </div>
                  </div>
                  <div className="mediaPressRel-content4">
                    <div className="mediaPressRel-info">
                      <div className="mediaPressRel-container2" />
                    </div>
                    <div className="mediaPressRel-content5">
                      <div className="mediaPressRel-text2">Press Release</div>
                      <div className="mediaPressRel-heading2">
                        {news.title}
                      </div>
                      <div className="mediaPressRel-button7">
                        <div className="mediaPressRel-button-child1" />
                        <a href={'/press-release/'+ createSlugFromTitle(news.title)} target="_blank" className="mediaPressRel-read-more-parent">
                          <div className="mediaPressRel-read-more1">Read More</div>
                          <img
                            className="mediaPressRel-vector-icon"
                            alt=""
                            src={MediaSecondLayerChev1}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>


        </div>
      </div>
    </div>
  );
}

// import React from 'react'
// import "./mediaSecondLayer.css"
// import MediaSecondLayerImage1 from "../../images/pressReleaseImg1.jpeg"
// import MediaSecondLayerImage2 from "../../images/pressReleaseImg2.jpeg"
// import MediaSecondLayerImage3 from "../../images/pressReleaseImg3.jpeg"
// import MediaSecondLayerChev1 from "../../images/vector-63.svg"
// import MediaSecondLayerimg4 from "../../images/mask-group@2x.png"
// import MediaSecondLayerChev2 from "../../images/vector-177.svg"
// import MediaSecondLayerChev3 from "../../images/vector-06.svg"

// export default function MediaSecondLayer() {
//   return (
//     <div className="mediaPressRel-blog-34">
//       <div className="mediaPressRel-blog-34-inner">
//         <div className="mediaPressRel-heading-parent">
//           <div className="mediaPressRel-heading">Press Release</div>
//           <div className="mediaPressRel-button3">
//             <div className="mediaPressRel-button-child" />
//             <img className="mediaPressRel-button-item" alt="" src={MediaSecondLayerChev2} />
//             <div className="mediaPressRel-see-more-parent">
//               <div className="mediaPressRel-see-more1">See More</div>
//               <img className="mediaPressRel-group-child" alt="" src={MediaSecondLayerChev3} />
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="mediaPressRel-content3">
//         <div className="mediaPressRel-row">
//           {[MediaSecondLayerImage1, MediaSecondLayerImage2, MediaSecondLayerImage3].map((image, index) => (
//             <div key={index} className="mediaPressRel-card">
//               <div className="mediaPressRel-placeholder-image-parent">
//                 <img className="mediaPressRel-placeholder-image-icon" alt="" src={image} />
//                 <div className="mediaPressRel-mask-group-parent">
//                   <img className="mediaPressRel-mask-group-icon" alt="" src={MediaSecondLayerimg4} />
//                   <div className="mediaPressRel-feb">
//                     <p className="mediaPressRel-p">08</p>
//                     <p className="mediaPressRel-feb1">Feb</p>
//                   </div>
//                 </div>
//               </div>
//               <div className="mediaPressRel-content4">
//                 <div className="mediaPressRel-info">
//                   <div className="mediaPressRel-container2" />
//                   <div className="mediaPressRel-text1">5 min read</div>
//                 </div>
//                 <div className="mediaPressRel-content5">
//                   <div className="mediaPressRel-text2">Press Release</div>
//                   <div className="mediaPressRel-heading2">
//                     {index === 0 && "CIC Agri honours business partners with Lifetime Awards"}
//                     {index === 1 && "CIC Holdings PLC Kicked Off the Second Strategic Leadership Development Programme with PIM"}
//                     {index === 2 && "Education Scholarships for Employees Children"}
//                   </div>
//                   <div className="mediaPressRel-button7">
//                     <div className="mediaPressRel-button-child1" />
//                     <div className="mediaPressRel-read-more-parent">
//                       <div className="mediaPressRel-read-more1">Read More</div>
//                       <img className="mediaPressRel-vector-icon" alt="" src={MediaSecondLayerChev1} />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   )
// }
