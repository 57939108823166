import React from 'react'
import "./mediaMainBanner.css"
import MediaImage from "../../images/mediaRoomBanner.webp"

export default function MediaMainBanner() {
  return (
    <div>
      <div>
        <div style={{ position: "relative" }}>
          <img className="mediaMain-home-image" alt="" src={MediaImage} />
          <div className="mediaMain-image-text-box">
            <div className="mediaMain-image-background-text">
              <h1 className="mediaMain-image-topic">
                Media Room
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
