import React from "react";
import "./mediaStayInformed.css";
import MediaSecondLayerImage1 from "../../images/eventImg1.png";
import MediaSecondLayerImage2 from "../../images/eventImg2.png";
import MediaSecondLayerImage3 from "../../images/eventImg3.png";
import MediaSecondLayerChev1 from "../../images/vector-63.svg";
import MediaSecondLayerChev2 from "../../images/vector-17.svg";
import MediaSecondLayerChev3 from "../../images/vector-6.svg";

export default function MediaStayInformed() {
  return (
    <div>
      <div className="stayInformed-blog-34">
        <div className="stayInformed-blog-34-inner">
          <div className="stayInformed-heading-parent">
            <div className="stayInformed-heading">Corporate Events</div>
            <div className="stayInformed-button3">
              <div className="stayInformed-button-child" />
              <img
                className="stayInformed-button-item"
                alt=""
                src={MediaSecondLayerChev2}
              />
              <a href="/corporate-event" className="stayInformed-see-more-parent">
                <div className="stayInformed-see-more1">See More</div>
                <img
                  className="stayInformed-group-child"
                  alt=""
                  src={MediaSecondLayerChev3}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="stayInformed-content3">
          <div className="stayInformed-row">
            <div className="stayInformed-card">
              <div className="stayInformed-placeholder-image-parent">
                <img
                  className="stayInformed-placeholder-image-icon"
                  alt=""
                  src={MediaSecondLayerImage1}
                />
                <div className="stayInformed-mask-group-parent">
                  {/* <img className="stayInformed-mask-group-icon" alt="" src={MediaSecondLayerimg4}/> */}

                  <div className="stayInformed-feb">
                    <p className="stayInformed-p">16</p>
                    <p className="stayInformed-feb1">Feb</p>
                  </div>
                </div>
              </div>
              <div className="stayInformed-content4">
                <div className="stayInformed-content5">
                  <div className="stayInformed-text2">Corporate Events</div>
                  <div className="stayInformed-heading2">
                    CIC Annual Christmas Get–together was held at Hilton Colombo
                    Residences.
                  </div>
                  <div className="stayInformed-button7">
                    <div className="stayInformed-button-child1" />
                    <a href="/corporate-event" className="stayInformed-read-more-parent">
                      <div className="stayInformed-read-more1">Read More</div>
                      <img
                        className="stayInformed-vector-icon"
                        alt=""
                        src={MediaSecondLayerChev1}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="stayInformed-card">
              <div className="stayInformed-placeholder-image-parent">
                <img
                  className="stayInformed-placeholder-image-icon"
                  alt=""
                  src={MediaSecondLayerImage2}
                />
                <div className="stayInformed-mask-group-parent">
                  {/* <img
                        className="stayInformed-mask-group-icon"
                        alt=""
                        src={MediaSecondLayerimg4}
                      /> */}
                  <div className="stayInformed-feb">
                    <p className="stayInformed-p">08</p>
                    <p className="stayInformed-feb1">Feb</p>
                  </div>
                </div>
              </div>
              <div className="stayInformed-content4">
                <div className="stayInformed-content5">
                  <div className="stayInformed-text2">Corporate Events</div>
                  <div className="stayInformed-heading2">
                    CIC House was filled with smiles and laughter when CIC
                    celebrated Children’s Day.
                  </div>
                  <div className="stayInformed-button7">
                    <div className="stayInformed-button-child1" />
                    <a href="/corporate-event" className="stayInformed-read-more-parent">
                      <div className="stayInformed-read-more1">Read More</div>
                      <img
                        className="stayInformed-vector-icon"
                        alt=""
                        src={MediaSecondLayerChev1}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="stayInformed-card">
              <div className="stayInformed-placeholder-image-parent">
                <img
                  className="stayInformed-placeholder-image-icon"
                  alt=""
                  src={MediaSecondLayerImage3}
                />
                <div className="stayInformed-mask-group-parent">
                  {/* <img
                        className="stayInformed-mask-group-icon"
                        alt=""
                        src={MediaSecondLayerimg4}
                      /> */}
                  <div className="stayInformed-feb">
                    <p className="stayInformed-p">08</p>
                    <p className="stayInformed-feb1">Feb</p>
                  </div>
                </div>
              </div>
              <div className="stayInformed-content4">
                <div className="stayInformed-content5">
                  <div className="stayInformed-text2">Corporate Events</div>
                  <div className="stayInformed-heading2">
                    CIC Manussakama was relaunched with the 1st Danuma Daanaya
                    community program.
                  </div>
                  <div className="stayInformed-button7">
                    <div className="stayInformed-button-child1" />
                    <a href="/corporate-event" className="stayInformed-read-more-parent">
                      <div className="stayInformed-read-more1">Read More</div>
                      <img
                        className="stayInformed-vector-icon"
                        alt=""
                        src={MediaSecondLayerChev1}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
